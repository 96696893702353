import React from 'react'

import { FormContainer, StyledRow, MobilBackground } from '../components/StylesPages/membershipStyle'
import Layout from '../components/Layout'
import MembershipForm from '../components/MembershipForm'

export default () => {
  return (
    <Layout themeType="dark" currentPage={'membership'}>
      <MobilBackground />
      <StyledRow className="container">
        <FormContainer className="col-md-10 col-lg-8 col-xs-12 offset-lg-2 offset-md-1" style={{display: 'block', marginBottom:'25px'}}>
          <MembershipForm />
        </FormContainer>
      </StyledRow>
    </Layout>
  )
}
